import * as duckdb from '@duckdb/duckdb-wasm';

import _ from 'lodash';
import {DailySnapshot, DBSchema, SummaryRow} from "./dbTypes";
import {AsyncDuckDB, AsyncDuckDBConnection, LogLevel} from "@duckdb/duckdb-wasm";
import moment from "moment";
import {getDateSqlFromString} from "../util/dbUtils";
import {Area, TotalArea} from "../general-staff/dashboardTypes";
import {ALL_OCCUPIED_AREA_KEYS, OCCUPIED_AREA_KEYS} from "../general-staff/generalStaffConstants";

export type IngestDuckDbTable = {
    schema: DBSchema;
    loadData?: () => Promise<Object[]>;
    parquetPath?: string;
}

export async function initializeDB(): Promise<duckdb.AsyncDuckDB> {
    const bundles = duckdb.getJsDelivrBundles();
    const bundle = await duckdb.selectBundle(bundles);
    const workerUrl = URL.createObjectURL(
        new Blob([`importScripts("${bundle.mainWorker}");`], {
            type: 'text/javascript',
        })
    );
    const worker = new Worker(workerUrl);
    const logger = new duckdb.ConsoleLogger(LogLevel.WARNING);

    const db = new duckdb.AsyncDuckDB(logger, worker);
    await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
    console.log('Database initialized');

    await db.open({
        accessMode: duckdb.DuckDBAccessMode.READ_WRITE,
        query: {
            castTimestampToDate: true,
        },
    });

    return db;
}

class DuckDBClient {
    db: AsyncDuckDB | undefined;
    private conn: AsyncDuckDBConnection | undefined;
    private ingestTables: IngestDuckDbTable[] = [];

    getIngestTable(tableName: string): IngestDuckDbTable {
        return this.ingestTables[this.ingestTables.map(t => t.schema.name).indexOf(tableName)];
    }

    async refreshParquetTable(ingestTable: IngestDuckDbTable): Promise<void> {
        await this.rawQuery(`CREATE TABLE IF NOT EXISTS ${ingestTable.schema.name} AS SELECT DISTINCT * FROM '${ingestTable.parquetPath}'`);
    }

    async refreshTable(tableName: string): Promise<void> {
        const ingestTable = this.getIngestTable(tableName);
        if (ingestTable.parquetPath !== undefined) {
            await this.refreshParquetTable(ingestTable);
            return;
        }
        console.log('Refreshing in-memory table');
        const tables = await this.rawQuery(`SELECT name FROM sqlite_master WHERE type='table' AND name='${ingestTable.schema.name}'`)
        if (tables.length === 0) {
            console.log('no tables found, reloading data')
            await this.createTable(ingestTable.schema);
            const data = await ingestTable.loadData!!();
            await this.insert(ingestTable.schema.name, data);
        }
    }

    ingestTable(ingestTable: IngestDuckDbTable): Promise<void> {
        if (!this.ingestTables.map(t => t.schema.name).includes(ingestTable.schema.name)) {
            this.ingestTables.push(ingestTable);
            return this.refreshTable(ingestTable.schema.name);
        }
        return Promise.resolve();
    }

    async query(query: string, tableName: string): Promise<Object[]> {
        await this.refreshTable(tableName);
        return this.rawQuery(query);
    }

    private async rawQuery(query: string): Promise<Object[]> {
        await this.database();
        const result = await (await this.db!!.connect()).query(query);
        return result.toArray().map(r => Object.fromEntries(r));
    }

    async queryList<t>(query: string, tableName: string): Promise<t[][]> {
        if (tableName) {
            await this.refreshTable(tableName);
        }
        return this.toNestedList<t>(await this.rawQuery(query));
    }

    async mostRecentDate(tableName: string): Promise<Date> {
        const query = `SELECT max(date) as date from ${tableName}`;
        const result = (await (this.query(query, tableName)))[0] as { [key: string]: string };
        const dateParts = result['date'].split('-');
        return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
    }

    async summarize(
        tableName: string,
        startDate: string = '2022-02-24',
        endDate: string = moment().format('YYYY-MM-DD'),
        categories: string[]
    ): Promise<SummaryRow[]> {
        await this.refreshTable(tableName);
        const [startYear, startMonth, startDay] = startDate.split('-');
        const [endYear, endMonth, endDay] = endDate.split('-');
        const categoryProjection = categories.join(", ");
        const dateFilter = `date BETWEEN make_date(${startYear}, ${startMonth}, ${startDay}) and make_date(${endYear}, ${endMonth}, ${endDay})`
        const tableSummary = (await (await this.db!!.connect()).query(
            `SUMMARIZE SELECT ${categoryProjection} FROM ${tableName} where ${dateFilter}`
        ))
            .toArray().map(r => Object.fromEntries(r))
            .filter(r => r['column_type'] !== 'DATE');
        const totalProjection = tableSummary.map(s => `sum(${s['column_name']}) as ${s['column_name']}`).join(',');
        const totalsQuery = `SELECT ${totalProjection} from ${tableName} where ${dateFilter}`;
        const totals = await this.rawQuery(totalsQuery);
        const totalValues: number[] = Object.values(totals[0]).map(t => t.reduce((a: number, b: number) => a + b));
        return _.range(0, totalValues.length).map(index => {
            return {
                category: tableSummary[index]['column_name'],
                'total losses': totalValues[index],
                max: tableSummary[index]['max'],
                min: tableSummary[index]['min'],
                mean: tableSummary[index]['avg'] === null ? null : Math.round(Number.parseFloat(tableSummary[index]['avg'] as string) * 1000) / 1000,
                median: tableSummary[index]['q50'],
                '25th percentile': tableSummary[index]['q25'],
                '75th percentile': tableSummary[index]['q75'],
                'standard deviation': tableSummary[index]['std'] === null ? null : Math.round(Number.parseFloat(tableSummary[index]['std'] as string) * 1000) / 1000,
            } as SummaryRow;
        });
    }

    async dailySnapshot(increaseTableName: string, totalTableName: string, date: string, includeColumns: string[]): Promise<DailySnapshot> {
        const dateParts = date.split('-');
        const projection = includeColumns.map(c => `cast(${c} as string) as ${c}`).join(', ');
        const increaseValuesQuery = `SELECT ${projection} from ${increaseTableName} where date = ${getDateSqlFromString(date)}`;
        const increaseValues = (await this.query(increaseValuesQuery, increaseTableName))[0] as { [key: string]: number };
        const totalValuesQuery = `SELECT ${projection} from ${totalTableName} where date = ${getDateSqlFromString(date)}`;
        const totalValues = (await this.query(totalValuesQuery, totalTableName))[0] as { [key: string]: number };
        const queries = includeColumns.map(c => {
            const divisorQuery = `(SELECT COUNT(${c}) from ${increaseTableName} where date <= ${getDateSqlFromString(date)})`;
            return `(SELECT ROUND(COUNT(${c})/${divisorQuery} * 100) as ${c} from ${increaseTableName} where date <= ${getDateSqlFromString(date)} and ${c} <= (SELECT ${c} from ${increaseTableName} where date = ${getDateSqlFromString(date)}))`
        });
        const rankQuery = `SELECT ${projection} FROM ${queries.join(" cross join ")}`
        const rankedResults = (await this.rawQuery(rankQuery))[0] as { [key: string]: string };

        const fewestMostResults: Promise<{ [key: string]: string }>[] = Object.entries(increaseValues)
            .map( async ([cat, count]) => {
                const mostSinceQuery = `SELECT max(date) as fewer_${cat} from ${increaseTableName} where ${cat} < ${count} and date < ${getDateSqlFromString(date)}`
                const fewestSinceQuery = `SELECT max(date) as more_${cat} from ${increaseTableName} where ${cat} > ${count} and date < ${getDateSqlFromString(date)}`
                const mostSinceResults  = (await this.rawQuery(mostSinceQuery))[0] as { [key: string]: string };
                const fewestSinceResults = (await this.rawQuery(fewestSinceQuery))[0] as { [key: string]: string };
                return {
                    ...fewestSinceResults,
                    ...mostSinceResults
                };
        });
        const fewestMost = (await Promise.all(fewestMostResults)).reduce((a, b) => Object.assign(a, b), {});
        return {
            date: new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])),
            dailyTotals: increaseValues,
            sumTotals: totalValues,
            ranks: rankedResults,
            fewerMoreThan: fewestMost,
        };
    }

    async area(tableName: string, date: string): Promise<Area[]> {
        const query = `SELECT * FROM ${tableName} where date = ${getDateSqlFromString(date)} ORDER BY ts_seconds desc`;
        const areas = (await this.query(query, tableName)).map(r => r as Area)
        if (areas.length === 0) {
            return areas;
        }
        return areas.filter(area => area.ts_seconds === areas[0].ts_seconds)
    };

    async dailyOccupiedAreaDeltas(tableName: string): Promise<Area[]> {
        const dailyOccupiedRows = `SELECT hash, area, percent, type, date, ts_seconds, row_number() OVER (PARTITION BY date ORDER BY ts_seconds desc) as row_num FROM ${tableName} where type IN (${OCCUPIED_AREA_KEYS.map(k => `'${k}'`).join(', ')})`;
        const query = `SELECT a.date, a.hash, a.area - lag(a.area) OVER (ORDER BY a.date) as area, a.percent, a.type, a.ts_seconds from (${dailyOccupiedRows}) a where a.row_num = 1`;
        return (await this.query(query, tableName)) as Area[];
    }

    async totalOccupiedArea(tableName: string): Promise<TotalArea[]> {
        const totalOccupiedRows = `SELECT max(date) as date, sum(area) as area, CONCAT(CAST(sum(CAST(SUBSTRING(percent, 1 , LEN(percent) - 1) as INT)) as varchar), '%') as percent, ts_seconds, count(type) as type_count FROM ${tableName} where type IN (${ALL_OCCUPIED_AREA_KEYS.map(k => `'${k}'`).join(', ')}) group by ts_seconds`;
        const query = `SELECT date, area, percent, ts_seconds from (${totalOccupiedRows}) a where type_count = 2 order by date`;
        return (await this.query(query, tableName)) as TotalArea[];
    }

    async createTable(schema: DBSchema): Promise<void> {
        const typeMapping = schema.fields
            .map(f => {
                return `${f.name} ${f.type}`
            })
            .join(', ')
        await (await this.db!!.connect()).query(`CREATE TABLE IF NOT EXISTS ${schema.name} (${typeMapping})`);
    }

    async insert(tableName: string, data: Object[]): Promise<Object[]> {
        if (!data.length) return [];
        const columnList = '(' +
            Object.keys(data[0])
                .join(', ') +
            ')';
        const valueList = data
            .map(r => '(' +
                Object.values(r)
                    .map(v => this.formatValue(v)).join(',') +
                ')')
            .join(', ')
        return this.query(
            `INSERT INTO ${tableName} ${columnList} VALUES ${valueList}`,
            tableName
        );
    }

    async database() {
        if (!this.db) {
            this.db = await initializeDB();
            await this.db.open({
                query: {
                    castTimestampToDate: true,
                },
            });
        }
        return this.db;
    }

    async connection() {
        await this.database();
        return await this.db!!.connect();
    }

    async reconnect() {
        if (this.conn) {
            await this.conn.close();
        }
        delete this.conn;
    }

    formatValue(value: any): string {
        const t = typeof value;
        switch (t) {
            case "bigint":
            case "number":
            case "boolean":
                return value;
            case "string":
                return `'${value}'`;
            case "undefined":
                return 'null'
            case "object":
                return this.formatObjectValue(value);
            default:
                throw new Error(`unsupported type ${t}`)
        }
    }

    formatObjectValue(value: object): string {
        if (
            value instanceof String ||
            value instanceof Date
        ) {
            return `'${value.toString}`;
        } else if (
            value instanceof Number
        ) {
            return value.toString();
        }
        throw new Error(`unsupported type ${value.constructor.name}`);
    }

    toNestedList<t>(data: Object[]): t[][] {
        return data.map(r => Object.values(r).map(v => v as t));
    }
}

export default DuckDBClient;
