import React, {useEffect, useRef, useState} from 'react';
import {Box, Center, Divider, Grid, GridCol, LoadingOverlay, Paper, Stack, Text} from '@mantine/core';
import {max, min} from "lodash";
import {DEEP_STATE_LINK_DESCRIPTION} from "./generalStaffConstants";
import '@mantine/core/styles.css';
import WidgetHeader from "../dashboards/WidgetHeader";
import SourceHoverCard from "./SourceHoverCard";
import {Area, AreaDelta, TotalArea} from "./dashboardTypes";
import {Chart, GoogleDataTableColumnRoleType, ReactGoogleChartEvent} from "react-google-charts";
import {formatAreaDeltasAndRows, formatTotalAreasAndRows} from "../util/chartUtils";
import {MAP_START_DATE} from "../util/constants";
import {dateStr, fillDateArray, stringToDate} from "../util/dateUtils";
import {useMeasure} from "react-use";

const fullAreaDeltas: { [key: string]: number } = {}
const dummyTotalRow = (date: Date) => [
    { v: dateStr(date) },
    { v: 0 },
    { v: 'color: #880808; opacity: 0.4; fill-color: #880808;' },
    { v: null },
    { v: null },
    { v: null }
];

fillDateArray(MAP_START_DATE)
    .map(d => dateStr(d))
    .forEach(ds => fullAreaDeltas[ds] = 0);

const dummyTotalRows = fillDateArray(MAP_START_DATE)
    .map(d => dummyTotalRow(d));

function AreaLossChart(props: AreaLossChartProps) {
    const [dataLoading, setDataLoading] = useState(true);
    const [areaDeltas, setAreaDeltas] = useState<AreaDelta[]>([[new Date(2022, 4, 4), 0]]);
    const chartBoxRef = useRef<HTMLInputElement>(null);
    const chartDivRef = useRef<HTMLInputElement>(null);
    const [setChartBoxRef, { width }] = useMeasure();
    const [setChartDivRef, { height }] = useMeasure();
    const [rows, setRows] =
        useState<{v: (Date | number | string)}[][]>(
            formatAreaDeltasAndRows([[new Date(2022, 4, 4), 0]])[1]);
    const [totalRows, setTotalRows] =
        useState<{v: (Date | number | string | null)}[][]>(dummyTotalRows);

    useEffect(() => {
        setDataLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        if (chartBoxRef?.current) {
            setChartBoxRef(chartBoxRef.current);
        }
        if (chartDivRef?.current) {
            setChartDivRef(chartDivRef.current)
        }
    }, [setChartBoxRef, setChartDivRef]);


    useEffect(() => {
        props.areaDeltas.forEach(area => {
            try {
                fullAreaDeltas[area.date] = area.area || 0;
            } catch {
                console.warn('bad areaDelta data', area);
            }
        });
        const [deltas, newRows] = formatAreaDeltasAndRows(Object.entries(fullAreaDeltas).map(a => [stringToDate(a[0]), a[1]]));
        setAreaDeltas(deltas);
        setRows(newRows);
    }, [props.areaDeltas]);

    useEffect(() => {
        if (props.areaTotals) {
            console.log("props.areaTotals", props.areaTotals);
            setTotalRows(formatTotalAreasAndRows(props.areaTotals));
        }
    }, [props.areaTotals]);

    const controlEvents: ReactGoogleChartEvent[] = [
        {
            eventName: "statechange",
            callback: ({ chartWrapper, controlWrapper }) => {
                if (controlWrapper) {
                    const startDate = controlWrapper.getState().range.start as Date;
                    const endDate = controlWrapper.getState().range.end as Date;
                    const startDateString = dateStr(startDate);
                    const endDateString = dateStr(endDate);
                    chartWrapper.getChart()
                    chartWrapper.setOptions(getNewOptions(startDateString, endDateString));
                }
            }
        }
    ];

    const chartEvents: ReactGoogleChartEvent[] = [
        {
            eventName: "ready",
            callback: () => {
                const rectElements = document.getElementById("area-loss-chart")!!.getElementsByTagName("rect");
                Array.prototype.forEach.call(rectElements, (el) => {
                    if (el.getAttribute("fill") === options.vAxis.baselineColor) {
                        el.setAttribute('stroke', options.vAxis.baselineColor)
                        el.setAttribute('stroke-width', '3')
                    }
                })
            }
        }
    ]

    const options = {
        legend: {position: 'none'},
        backgroundColor: 'none',
        chartArea: {left: 100, right: 30, top: 50, bottom: 30},
        hAxis: {
            gridlines: { color: 'transparent' },
        },
        vAxis: {
            viewWindow: {
                min: -1 * max([Math.abs(min(areaDeltas.map(a => a[1]))!!), max(areaDeltas.map(a => a[1]))!!])!!,
                max: max([Math.abs(min(areaDeltas.map(a => a[1]))!!), max(areaDeltas.map(a => a[1]))!!]),
            },
            gridlines: { color: 'transparent' },
            title: 'Change in Occupied Territory (square km)',
            titleTextStyle : {
                fontName : "Arial",
                italic : false,
                bold: true,
                color: "grey",
                fontSize: 24
            },
            baselineColor: '#d3d3d3',
            textPosition: 'none',
        }
    };

    const totalOptions = {
        legend: {position: 'none'},
        backgroundColor: 'none',
        chartArea: {left: 30, right: 30, top: 50, bottom: 30},
        lineWidth: 3,
        opacity: 0.5,
        annotations: {
            textStyle: {
                auraColor: 'lightgrey',
                fontSize: 24,
            },
            stem: {
                length: 12,
            }
        },
        hAxis: {
            gridlines: { color: 'transparent' },
            textPosition: 'none',
            baselineColor: 'none',
        },
        vAxis: {
            viewWindow: {
                min: 0,
                max: 130000,
            },
            gridlines: { color: 'transparent' },
            title: '',
            titleTextStyle : {
                fontName : "Arial",
                italic : false,
                bold: true,
                color: "grey",
                fontSize: 24
            },
            baselineColor: 'none',
            textPosition: 'none',
            format: "short"
        },
    };


    const getNewOptions = (startDate: string, endDate: string) => {
        const newOptions = { ...options }

        const minVAxis = min(
            Object.entries(fullAreaDeltas).filter(a => a[0] >= startDate && a[0] <= endDate)
                .map(a => a[1])
        );
        const maxVAxis = max(
            Object.entries(fullAreaDeltas).filter(a => a[0] >= startDate && a[0] <= endDate)
                .map(a => a[1])
        );
        const axisHeight = max([Math.abs(minVAxis!!), Math.abs(maxVAxis!!)]);
        newOptions.vAxis.viewWindow.min = axisHeight!! * -1;
        newOptions.vAxis.viewWindow.max = axisHeight;
        return newOptions;
    }

    const controls = [
        {
            controlEvents: controlEvents,
            controlType: "ChartRangeFilter" as "ChartRangeFilter",
            options: {
                filterColumnIndex: 0,
                ui: {
                    chartType: "LineChart",
                    chartOptions: {
                        chartArea: { width: "60%", height: "50%" },
                        hAxis: { baselineColor: "none", format: "MM-yyyy", slantedText: true, slantedTextAngle: 90 },
                        vAxis: { baselineColor: "none" }
                    },
                },
            },
            controlPosition: "bottom" as "bottom",
            controlWrapperParams: {
                state: {
                    range: {
                        start: MAP_START_DATE,
                        end: max(areaDeltas.map(a => a[0]))!!,
                    },
                },
            },
        },
    ];

    return (
        <Box pos="relative">
            <Paper shadow="md" p="xl" style={{height: 1250}}>
                <WidgetHeader title={"Occupied Territory"} />
                <LoadingOverlay
                    visible={dataLoading}
                    zIndex={1000}
                    overlayProps={{ radius: "sm", blur: 2 }}
                />
                <Box pos="relative" style={{height: 750}} ref={chartBoxRef} id="area-loss-chart">
                    <Box pos="relative" style={{width: width, height: 100, marginTop: 50}}>
                        <div style={{position: "relative", width: "100%", zIndex: 2}}>
                            <Grid gutter={0}>
                                <GridCol span={0.5}>
                                    <Text
                                        ta="left"
                                        c="grey"
                                        fw={500}
                                        style={{
                                            transform: 'rotate(-270deg)',
                                            bottom: '54%',
                                            position: 'absolute',
                                            left: '0.015vw',
                                            fontSize: 20
                                        }}
                                    >
                                        {/*{min(totalRows.map(r => r[0].v as Date))?.toISOString().slice(0, 10)}*/}
                                    </Text>
                                </GridCol>
                                <GridCol span={11}>
                                    <Chart
                                        chartType="AreaChart"
                                        data={null as unknown as any[]}
                                        rows={totalRows}
                                        columns={[
                                            {type: "date", label: "Date"},
                                            {type: "number", label: "change in occupied sq km"},
                                            {
                                                type: "string",
                                                label: "color",
                                                role: 'style' as GoogleDataTableColumnRoleType
                                            },
                                            {type: "string", role: 'tooltip' as GoogleDataTableColumnRoleType},
                                            {type: "string", role: "annotation" as GoogleDataTableColumnRoleType},
                                            {type: "string", role: "annotationText" as GoogleDataTableColumnRoleType},
                                        ]}
                                        options={totalOptions}
                                        width="100%"
                                        height="200px"
                                    />
                                    <div style={{height: 80, zIndex: 1}}/>
                                    <Center style={{position: "absolute", width: "100%", height: 60, zIndex: 112, top: 100}}>
                                        <Text c="white" ta="center" fw={700} style={{fontSize: 32}}>
                                            {'<-'} Occupied Area of Ukraine over Time {'->'}
                                        </Text>
                                    </Center>
                                </GridCol>
                                <GridCol span={0.5} style={{height: '100%'}}>
                                    <Text
                                        ta="left"
                                        c="grey"
                                        fw={500}
                                        style={{
                                            transform: 'rotate(-270deg)',
                                            bottom: '54%',
                                            position: 'absolute',
                                            right: '0.015vw',
                                            fontSize: 20
                                        }}
                                    >
                                        {/*{max(totalRows.map(r => r[0].v as Date))?.toISOString().slice(0, 10)}*/}
                                    </Text>
                                </GridCol>
                            </Grid>
                        </div>
                    </Box>
                    <Divider size={"lg"} style={{opacity: 0.5, marginTop: 100}}/>
                    <Text ta={"center"} fw={700} c="grey" style={{marginTop: 30, fontSize: 48}}>Daily Changes in Occupied Territory</Text>
                    <div style={{position: 'absolute', width: width, zIndex: 10}} ref={chartDivRef}>
                        <Chart
                            chartType="LineChart"
                            data={null as unknown as any[]}
                            rows={rows}
                            columns={[
                                {type: "date", label: "Date"},
                                {type: "number", label: "change in occupied sq km"},
                                {type: "string", label: "color", role: 'style' as GoogleDataTableColumnRoleType},
                                {type: "string", role: 'tooltip' as GoogleDataTableColumnRoleType}
                            ]}
                            options={options}
                            width="100%"
                            height="600px"
                            controls={controls}
                            chartEvents={chartEvents}
                        />
                    </div>
                    <div style={{position: 'absolute', height: height, width: width}}>
                        <Stack>
                            <Center style={{height: height / 2, width: width}}>
                                <Text
                                    ta={'center'}
                                    fw={700}
                                    c="#880808"
                                    style={{fontSize: 44, opacity: 0.6, zIndex: 0}}
                                >
                                    TERRITORY OCCUPIED
                                </Text>
                            </Center>
                            <Center style={{height: height / 2, width: width}}>
                                <Text
                                    ta={'center'}
                                    fw={700}
                                    c="#0057B7"
                                    style={{fontSize: 44, opacity: 0.6, zIndex: 0}}
                                >
                                    TERRITORY LIBERATED
                                </Text>
                            </Center>
                        </Stack>
                    </div>
                </Box>
                <Box pos="absolute" w="95%" style={{marginRight: "10%", bottom: 30}}>
                    <SourceHoverCard links={[DEEP_STATE_LINK_DESCRIPTION]}/>
                </Box>
            </Paper>
        </Box>
    );
}

type AreaLossChartProps = {
    query: (q: string, t: string) => Promise<Object[]>;
    queryList: <t, >(q: string, t: string) => Promise<t[][]>;
    insert: (data: Object[]) => Promise<Object[]>;
    areaDeltas: Area[];
    areaTotals: TotalArea[];
    loading: boolean;
}

export default AreaLossChart;
