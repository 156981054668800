
export const stringToDate = (dateString: string) => {
    try {
        const dateParts = dateString.split('-')
        return new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
    } catch (e) {
        console.log(dateString);
        throw e;
    }
}

export const dateStr = (date: Date) => {
    return date.toISOString().slice(0, 10);
}

export const fillDateArray = (startDate: Date, endDate?: Date) => {
    let loop = new Date(startDate.setDate(startDate.getDate() - 1));
    const finishDate = endDate ? endDate : new Date();
    const dateArray: Date[] = [];
    while (loop <= finishDate) {
        dateArray.push(loop);
        if (!dateStr(loop).startsWith('2')) {
            console.error(dateStr(loop));
        }
        loop = new Date(loop.setDate(loop.getDate() + 1));
    }
    return dateArray

}