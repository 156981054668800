import React from 'react';
import { Slider, Title, Text } from '@mantine/core';

function SMASlider(props: SMASliderProps) {
    return (
        <>
            <Title
                style={{ marginTop: 10 }}
                order={5}
            >
                <Text size={'sm'}>{`Simple Moving Average (${props.selectedValue} ${props.selectedValue === 1 ? 'day' : 'days'})`}</Text>
            </Title>
            <Slider
                label={(value) => `${value} days`}
                value={props.selectedValue}
                min={props.minValue}
                max={props.maxValue}
                onChange={props.onChange}>
            </Slider>
        </>
    );
}

type SMASliderProps = {
    minValue: number;
    maxValue: number;
    selectedValue: number;
    onChange: (value: number) => void
}

export default SMASlider;
