

export const getTsType = (type: string) => {
    const typeLower = type.toLowerCase();
    switch (typeLower) {
        case "bigint":
        case "int8":
        case "long":
            return "bigint";

        case "double":
        case "float8":
        case "numeric":
        case "decimal":
        case "decimal(s, p)":
        case "real":
        case "float4":
        case "float":
        case "float32":
        case "float64":
            return "number";

        case "hugeint":
        case "integer":
        case "smallint":
        case "tinyint":
        case "ubigint":
        case "uinteger":
        case "usmallint":
        case "utinyint":
        case "int4":
        case "int":
        case "signed":
        case "int2":
        case "short":
        case "int1":
        case "int64":
        case "int32":
            return "integer";

        case "boolean":
        case "bool":
        case "logical":
            return "boolean";

        case "date":
        case "interval": // date or time delta
        case "time":
        case "timestamp":
        case "timestamp with time zone":
        case "datetime":
        case "timestamptz":
            return "date";

        case "uuid":
        case "varchar":
        case "char":
        case "bpchar":
        case "text":
        case "string":
        case "utf8": // this type is unlisted in the `types`, but is returned by the db as `column_type`...
            return "string";
        default:
            return "other";
    }
};

export const getDBType = (type: string) => {
    const typeLower = type.toLowerCase();
    switch (typeLower) {
        case "bigint":
            return "bigint";

        case "number":
            return "double";

        case "integer":
            return "bigint";

        case "boolean":
            return "boolean";

        case "date":
            return "date";

        case "string":
            return "string";

        default:
            return "other";
    }
};

export type DBField = {
    name: string,
    type: string,
};

export type DBSchema = {
    name: string,
    fields: DBField[],
};

export type SummaryRow = {
    category: string,
    'total losses': number,
    max: number,
    min: number,
    mean: number,
    median: number,
    '25th percentile': number,
    '75th percentile': number,
    'standard deviation': number,
};

export type AreaSummary = {
    total: number,
    max: number,
    min: number,
    mean: number,
    median: number,
    '25th percentile': number,
    '75th percentile': number,
    'standard deviation': number,
}

export const DBTypes = {
    Integer: 'INTEGER',
    Double: 'DOUBLE',
    Date: 'DATE',
    string: 'STRING',
}

export type DailySnapshot = {
    date: Date,
    dailyTotals: { [key: string]: number },
    sumTotals: { [key: string]: number },
    ranks: { [key: string]: string },
    fewerMoreThan: { [key: string]: string },
};
