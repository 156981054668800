import DuckDBClient from "../duckdb/duckDBClient";
import {
    CATEGORIES,
    GENERAL_STAFF_INCREASE_PARQUET_PATH,
    GENERAL_STAFF_INCREASE_SCHEMA,
    GENERAL_STAFF_TOTAL_PARQUET_PATH,
    GENERAL_STAFF_TOTAL_SCHEMA,
    INCREASE_TABLE_NAME,
    TOTAL_TABLE_NAME
} from "../general-staff/generalStaffConstants";
import {getDateSqlFromString} from "../util/dbUtils";
import {dateStr} from "../util/dateUtils";

export default class GeneralStaffDao {
    private dbClient: DuckDBClient;
    private increaseIngested = false;
    private totalIngested = false;

    constructor(db: DuckDBClient) {
        this.dbClient = db;
    }

    async ingestTables(): Promise<void> {
        if (!this.increaseIngested || !this.totalIngested) {
            const ingestIncrease = this.dbClient.ingestTable({schema: GENERAL_STAFF_INCREASE_SCHEMA, parquetPath: GENERAL_STAFF_INCREASE_PARQUET_PATH});
            const ingestTotal = this.dbClient.ingestTable({schema: GENERAL_STAFF_TOTAL_SCHEMA, parquetPath: GENERAL_STAFF_TOTAL_PARQUET_PATH});
            await Promise.all([ingestIncrease, ingestTotal]);
            this.increaseIngested = true;
            this.totalIngested = true;
        }
        return Promise.resolve();
    }

    // Return all areas for a given date. Return empty list if no areas found.
    async getSnapshot(date: Date, includeColumns: string[]) {
        const formattedDate = dateStr(date);
        await this.ingestTables();
        return this.dbClient.dailySnapshot(INCREASE_TABLE_NAME, TOTAL_TABLE_NAME, formattedDate, includeColumns);
    }

    async getMostRecentDate(tableName: string) {
        await this.ingestTables();
        return this.dbClient.mostRecentDate(tableName);
    }

async getIncreaseValues(date: string, includeColumns: string[]): Promise<{ [key: string]: number }> {
        const projection = includeColumns.map(c => `cast(${c} as string) as ${c}`).join(', ');
        const increaseValuesQuery = `SELECT ${projection} from ${INCREASE_TABLE_NAME} where date = ${getDateSqlFromString(date)}`;
        await this.ingestTables();
        return (await this.dbClient.query(increaseValuesQuery, INCREASE_TABLE_NAME))[0] as { [key: string]: number };
    }

    async getTotalValues(date: string, includeColumns: string[]) {
        const projection = includeColumns.map(c => `cast(${c} as string) as ${c}`).join(', ');
        const totalValuesQuery = `SELECT ${projection} from ${TOTAL_TABLE_NAME} where date = ${getDateSqlFromString(date)}`;
        await this.ingestTables();
        return (await this.dbClient.query(totalValuesQuery, TOTAL_TABLE_NAME))[0] as { [key: string]: number };
    }

    async getSnapshotData(date: string, includeColumns: string[]): Promise<[{ [p: string]: string }, { [p: string]: number }, { [p: string]: number }, { [p: string]: string }]> {
        const rankedResults = await this.getRankedResults(date, includeColumns);
        const totalValues = await this.getTotalValues(date, includeColumns);
        const increaseValues = await this.getIncreaseValues(date, includeColumns);
        const fewestMost = await this.getFewestMost(date, increaseValues);
        return [rankedResults, increaseValues, totalValues, fewestMost];
    }

    async getRankedResults(date: string, includeColumns: string[]) {
        const projection = includeColumns.map(c => `cast(${c} as string) as ${c}`).join(', ');
        const queries = includeColumns.map(c => {
            const divisorQuery = `(SELECT COUNT(${c}) from ${INCREASE_TABLE_NAME} where date <= ${getDateSqlFromString(date)})`;
            return `(SELECT ROUND(COUNT(${c})/${divisorQuery} * 100) as ${c} from ${INCREASE_TABLE_NAME} where date <= ${getDateSqlFromString(date)} and ${c} <= (SELECT ${c} from ${INCREASE_TABLE_NAME} where date = ${getDateSqlFromString(date)}))`
        });
        const rankQuery = `SELECT ${projection} FROM ${queries.join(" cross join ")}`
        await this.ingestTables();
        return (await this.dbClient.query(rankQuery, INCREASE_TABLE_NAME))[0] as { [key: string]: string };
    }

    async getFewestMost(date: string, increaseValues: { [p: string]: number }) {
        await this.ingestTables();
        const fewestMostResults: Promise<{ [key: string]: string }>[] = Object.entries(increaseValues)
            .map( async ([cat, count]) => {
                const mostSinceQuery = `SELECT max(date) as fewer_${cat} from ${INCREASE_TABLE_NAME} where ${cat} < ${count} and date < ${getDateSqlFromString(date)}`
                const fewestSinceQuery = `SELECT max(date) as more_${cat} from ${INCREASE_TABLE_NAME} where ${cat} > ${count} and date < ${getDateSqlFromString(date)}`
                const mostSinceResults  = (await this.dbClient.query(mostSinceQuery, INCREASE_TABLE_NAME))[0] as { [key: string]: string };
                const fewestSinceResults = (await this.dbClient.query(fewestSinceQuery, INCREASE_TABLE_NAME))[0] as { [key: string]: string };
                return {
                    ...fewestSinceResults,
                    ...mostSinceResults
                };
            });
        return (await Promise.all(fewestMostResults)).reduce((a, b) => Object.assign(a, b), {});
    }

    async getSummary(startDate: string, endDate: string) {
        await this.ingestTables();
        return (await this.dbClient.summarize(INCREASE_TABLE_NAME, startDate, endDate, CATEGORIES));
    }

    async getLossesOverSma(smaDays: number, categories: string[]) {
        await this.ingestTables();
        const smaQueries = categories.map(c => `ROUND(AVG(IF (${c} < 0, 0, ${c})) OVER(ORDER BY date ROWS BETWEEN ${smaDays} PRECEDING AND CURRENT ROW), 2) AS ${c}`);
        const finalQuery = `SELECT cast(date as DATE) as date, ${smaQueries.join(", ")} from ${INCREASE_TABLE_NAME} ORDER BY date OFFSET ${smaDays}`;
        const r = await this.dbClient.queryList<(String | Number | Date)>(finalQuery, INCREASE_TABLE_NAME);
        return [
            ['date', ...categories],
            ...r,
            ];
    }

}
