import {AppShell, Button, Group, Highlight, Text} from "@mantine/core";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import HeaderAboutButton from "./HeaderAboutButton";
import HeaderDashboardButton from "./HeaderDashboardButton";


function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <AppShell.Header>
            <Group h="100%" px="md">
                <Button
                    variant={'transparent'}
                    leftSection={
                        <img
                            width="24"
                            alt="Lesser Coat of Arms of Ukraine"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Lesser_Coat_of_Arms_of_Ukraine.svg/64px-Lesser_Coat_of_Arms_of_Ukraine.svg.png"
                        />
                    }
                    rightSection={
                        <Highlight
                            size={'24px'}
                            highlight={['Ukraine War Statistics']}
                            highlightStyles={{
                                backgroundImage: 'linear-gradient(3turn, #FFDD00, #0057B7, white)',
                                fontWeight: 700,
                                fontSize: 24,
                                opacity: 0.8,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}>
                            Ukraine War Statistics
                        </Highlight>
                }
                    onClick={() => {
                        if (location.pathname !== '/') {
                            console.log('GOING HOME');
                            navigate('/home');
                        }
                    }}
                />
                <HeaderDashboardButton
                    onClick={() => {
                    if (location.pathname !== '/dashboard') {
                        console.log('GOING TO DASHBOARD');
                        navigate('/dashboard');
                    }}}
                />
                <HeaderAboutButton onClick={() => navigate('/about')} />
            </Group>
        </AppShell.Header>
    );
}

export default Header;
