import {AppShell, Grid, GridCol, Stack, Text, Title, UnstyledButton} from "@mantine/core";
import React, {useState} from "react";
import NavBarDashboardButton from "./NavBarDashboardButton";
import {useRecoilState} from "recoil";
import {navbarCollapsedState} from "../atoms/appShellAtoms";

function UWSDashboardAppShellNavbar() {
    const [navbarCollapsed, setNavbarCollapsed] = useRecoilState(navbarCollapsedState);
    const [navbarToggleFocused, setNavbarToggleFocused] = useState(false);

    return (
        <AppShell.Navbar
            p="md"
        >
            <Grid>
                <GridCol span={10.5}>
                    {!navbarCollapsed && (
                        <Stack>
                            <Title order={2}>Russian Losses</Title>
                            <NavBarDashboardButton dashboardName={'General Staff'} dashboardPath={'/dashboard'}/>
                        </Stack>
                    )}
                </GridCol>
                <GridCol span={1.5}>
                    <UnstyledButton
                        style={{
                            fullWidth: true,
                            height: '100%',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: 45,
                            padding: 0,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            backgroundColor: navbarToggleFocused ? 'lightgrey' : 'white'
                        }}
                        onClick={event => setNavbarCollapsed(!navbarCollapsed)}
                        onPointerEnter={event => setNavbarToggleFocused(true)}
                        onPointerLeave={event => setNavbarToggleFocused(false)}
                    >
                        <Text size={'xl'} ta={'center'}>{navbarCollapsed ? '>' : '<'}</Text>
                    </UnstyledButton>
                </GridCol>
            </Grid>
        </AppShell.Navbar>
    );
}

export default UWSDashboardAppShellNavbar;
