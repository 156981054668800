import {Button, Text} from "@mantine/core";
import React from "react";
import { BiQuestionMark } from "react-icons/bi";


function HeaderAboutButton(props: HeaderAboutButtonProps) {
    return (
        <Button
            variant={"transparent"}
            onClick={props.onClick}
        >
            <Text size={'lg'} fw={500} style={{marginRight: 0, padding: 0}} c={"#0057B7"}>About</Text>
            <BiQuestionMark size={20} style={{marginLeft: 0, padding: 0, color: "#0057B7"}} />
        </Button>
    );
}

type HeaderAboutButtonProps = {
    onClick: () => void;
};

export default HeaderAboutButton;
