import React, {useCallback, useEffect, useState} from 'react';
import GeneralStaffChart from "./GeneralStaffChart";
import DuckDBClient, {IngestDuckDbTable} from "../duckdb/duckDBClient";
import {
    CATEGORIES,
    GENERAL_STAFF_INCREASE_SCHEMA,
    GENERAL_STAFF_INCREASE_PARQUET_PATH,
    INCREASE_TABLE_NAME,
    GENERAL_STAFF_TOTAL_SCHEMA,
    GENERAL_STAFF_TOTAL_PARQUET_PATH,
    DEEP_STATE_AREA_SCHEMA,
    DEEP_STATE_AREA_PARQUET_PATH, AREA_TABLE_NAME
} from "./generalStaffConstants";
import {Grid, GridCol} from "@mantine/core";
import GeneralStaffSummaryTable from "./GeneralStaffSummaryTable";
import {SummaryRow} from "../duckdb/dbTypes";
import '@mantine/core/styles.css';
import GeneralStaffSnapshot from "./GeneralStaffSnapshot";
import {useRecoilState} from "recoil";
import {navbarCollapsedState} from "../atoms/appShellAtoms";
import {Area, TotalArea} from "./dashboardTypes";
import AreaLossChart from "./AreaLossChart";
import AreaDao from "../dao/AreaDao";
import SnapshotService from "../service/SnapshotService";
import GeneralStaffDao from "../dao/GeneralStaffDao";
import SummaryService from "../service/SummaryService";
import LossService from "../service/LossService";

const dummyAreaDeltas: Area[] = [{ts_seconds: 123456789, date: "2022-04-17", area: 0.0, percent: "0%", hash: "#0876234", type: "loss"}]
const dummyAreaTotals: TotalArea[] = [{ts_seconds: 123456789, date: "2022-04-17", area: 0.0, percent: "0%"}]

const duckDbClient = new DuckDBClient();
const areaDao: AreaDao = new AreaDao(duckDbClient);
const generalStaffDao: GeneralStaffDao = new GeneralStaffDao(duckDbClient);

function GeneralStaffDashboard() {
    const [db, ] = useState(duckDbClient);
    const [snapshotService, ] = useState(new SnapshotService(areaDao, generalStaffDao));
    const [summaryService, ] = useState(new SummaryService(areaDao, generalStaffDao));
    const [lossService, ] = useState(new LossService(generalStaffDao));
    const [loading, setLoading] = useState(true);
    const [navbarCollapsed,] = useRecoilState(navbarCollapsedState);
    const [areaDeltas, setAreaDeltas] = useState(dummyAreaDeltas);
    const [areaTotals, setAreaTotals] = useState(dummyAreaTotals);

    const query = (query: string): Promise<Object[]> => {
            return db!!.query(query, INCREASE_TABLE_NAME);
    }

    const queryList = <t,>(query: string): Promise<t[][]> => {
            return db!!.queryList<t>(query, INCREASE_TABLE_NAME);
    }

    const ingestTable = useCallback(async (ingestTable: IngestDuckDbTable): Promise<void> => {
        return await db.ingestTable(ingestTable)
    }, [db]);

    const insert = (data: Object[]): Promise<Object[]> => {
        return new Promise((resolve) => {
            return resolve(db!!.insert(INCREASE_TABLE_NAME, data));
        });
    }

    const summarize = (startDate: string | undefined, endDate: string | undefined): Promise<SummaryRow[]> => {
        return db!!.summarize(INCREASE_TABLE_NAME, startDate, endDate, CATEGORIES);
    }

    useEffect(() => {
        ingestTable({schema: GENERAL_STAFF_INCREASE_SCHEMA, parquetPath: GENERAL_STAFF_INCREASE_PARQUET_PATH})
            .then(async () => {
                await ingestTable({
                    schema: GENERAL_STAFF_TOTAL_SCHEMA,
                    parquetPath: GENERAL_STAFF_TOTAL_PARQUET_PATH
                });
                await ingestTable({
                    schema: DEEP_STATE_AREA_SCHEMA,
                    parquetPath: DEEP_STATE_AREA_PARQUET_PATH
                });
                const areaDeltaData = await db!!.dailyOccupiedAreaDeltas(AREA_TABLE_NAME);
                setAreaDeltas(areaDeltaData);
                setAreaTotals(await db!!.totalOccupiedArea(AREA_TABLE_NAME));
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    }, [db, ingestTable]);

    return (
        <Grid gutter={'xl'}>
            <GridCol span={12}>
                <GeneralStaffSnapshot
                    snapshotService={snapshotService}
                    collapsed={navbarCollapsed}
                />
            </GridCol>
            <GridCol span={12}>
                <GeneralStaffSummaryTable
                    summaryService={summaryService}
                />
            </GridCol>
            <GridCol span={12}>
                <GeneralStaffChart
                    lossService={lossService}
                />
            </GridCol>
            <GridCol span={12}>
                <AreaLossChart
                    query={query}
                    queryList={queryList}
                    insert={insert}
                    loading={loading}
                    areaDeltas={areaDeltas}
                    areaTotals={areaTotals}
                />
            </GridCol>
        </Grid>
    );
}

export default GeneralStaffDashboard;
