import React, {useCallback, useEffect, useState} from 'react';
import {Box, Table, Group, Paper, Text, LoadingOverlay, Space, Divider} from '@mantine/core';
import {DateInput} from '@mantine/dates';
import '@mantine/dates/styles.css'
import {AreaSummary, SummaryRow} from "../duckdb/dbTypes";
import '@mantine/core/styles.css';
import WidgetHeader from "../dashboards/WidgetHeader";
import {DEEP_STATE_LINK_DESCRIPTION, DISPLAY_NAME_MAP, GENERAL_STAFF_LINK_DESCRIPTION} from "./generalStaffConstants";
import {INCREASE_START_DATE} from "../util/constants";
import SourceHoverCard from "./SourceHoverCard";
import {dateStr, stringToDate} from "../util/dateUtils";
import SummaryService from "../service/SummaryService";

const differenceDays = (startDate: Date, endDate: Date) =>
    Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));

function GeneralStaffSummaryTable(props: GeneralStaffSummaryTableProps) {
    const [dataLoading, setDataLoading] = useState(true);
    const [summaryData, setSummaryData] = useState<SummaryRow[]>([]);
    const [areaSummaryData, setAreaSummaryData] = useState<AreaSummary>();
    const [startDate, setStartDate] = useState(INCREASE_START_DATE);
    const [endDate, setEndDate] = useState(new Date());
    const [dayCount, setDayCount] = useState(differenceDays(startDate, endDate));

    const summarize = useCallback((startDate: string, endDate: string) => {
        setDataLoading(true);
        props.summaryService.summarize(startDate, endDate)
            .then(values => {
                setSummaryData(values[0]);
                setAreaSummaryData(values[1][0]);
                setDayCount(differenceDays(stringToDate(startDate), stringToDate(endDate)));
                setDataLoading(false);
            })
            .catch((err) => {
                setDataLoading(false);
                console.error(err)
            });

    }, [props.summaryService]);

    useEffect(() => {
        summarize(dateStr(startDate), dateStr(endDate));
    }, [summarize, startDate, endDate]);

    return (
        <Box pos="relative">
            <Paper shadow="md" p="xl" style={{height: '100%'}}>
                <WidgetHeader title={`Summary of Losses (${dayCount} days)`} />
                <LoadingOverlay
                    visible={dataLoading}
                    zIndex={1000}
                    overlayProps={{ radius: "sm", blur: 2 }}
                />
                <Group>
                    <Text size={"lg"} c={"grey"} fw={500}>Start</Text>
                    <DateInput
                        value={startDate}
                        onChange={(value) => {
                            if (value !== null) {
                                setStartDate(value);
                                summarize(dateStr(value), dateStr(endDate));
                            }
                        }}
                        excludeDate={(value) => {
                            return value.getTime() < INCREASE_START_DATE.getTime() || value.getTime() > endDate.getTime();
                        }}
                    />
                    <Space />
                    <Text size={"lg"} c={"grey"} fw={500}>End</Text>
                    <DateInput
                        value={endDate}
                        onChange={(value) => {
                            if (value !== null) {
                                setEndDate(value);
                            }
                        }}
                        excludeDate={(value) => {
                            return value.getTime() < startDate.getTime() || value.getTime() > new Date().getTime();
                        }}
                    />
                </Group>
                {
                    !dataLoading && summaryData.length > 0 && (
                        <Table key={'General-Staff-Summary-Table'} style={{marginTop: 20, marginBottom: 20}}>
                            <Table.Thead key={'General-Staff-Summary-Table-Header'}>
                                <Table.Tr>
                                    {Object.keys(summaryData[0]).map(s => (
                                        <Table.Th key={`General-Staff-Summary-Table-Header-${s}`}>{s}</Table.Th>
                                    ))}
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody key={'General-Staff-Summary-Table-Body'}>
                                {summaryData.map(s => {
                                    return (
                                        <Table.Tr key={`General-Staff-Summary-Table-Row-${s.category}`}>
                                            {Array.from((Object.entries(s)).entries()).map(([index, [c, v]]) => (
                                                <Table.Td key={`General-Staff-Summary-Table-Row-${index}-${s.category}`}>{c === 'name' ? DISPLAY_NAME_MAP[v].name: v}</Table.Td>
                                            ))}
                                        </Table.Tr>
                                    );
                                })}
                            </Table.Tbody>
                        </Table>
                    )
                }
                <Divider size={"lg"} style={{opacity: 0.5}}/>
                <Text c={'grey'} fw={700} style={{paddingBottom: 10, paddingTop: 20, fontSize: 36}}>Territory</Text>
                { areaSummaryData !== undefined && (
                    <Group style={{paddingBottom: 20}}>
                        <Text fw={700} c={areaSummaryData.total < 0 ? '#0057B7' : '#880808'} style={{fontSize: 24}}>
                            {`+${Math.abs(areaSummaryData.total)} sq km ${areaSummaryData.total < 0 ? 'liberated' : 'occupied'}`}
                        </Text>
                        <Text c={'grey'} fw={700} style={{fontSize: 20}}>|</Text>
                        <Text fw={700} style={{fontSize: 20}}>
                            {`average: ${Math.abs(areaSummaryData.mean)} sq km ${areaSummaryData.total < 0 ? 'freed' : 'lost'} per day`}
                        </Text>
                        <Text c={'grey'} fw={700} style={{fontSize: 20}}>|</Text>
                        <Text fw={700} style={{fontSize: 20}}>
                            {`best day: ${Math.abs(areaSummaryData.min)} sq km ${areaSummaryData.min < 0 ? 'liberated' : 'occupied'}`}
                        </Text>
                        <Text c={'grey'} fw={700} style={{fontSize: 20}}>|</Text>
                        <Text fw={700} style={{fontSize: 20}}>
                            {`worst day: ${Math.abs(areaSummaryData.max)} sq km ${areaSummaryData.max < 0 ? 'liberated' : 'occupied'}`}
                        </Text>
                    </Group>
                )}
                <SourceHoverCard links={[GENERAL_STAFF_LINK_DESCRIPTION, DEEP_STATE_LINK_DESCRIPTION]} />
            </Paper>
        </Box>
    );
}

type GeneralStaffSummaryTableProps = {
    summaryService: SummaryService,
}

export default GeneralStaffSummaryTable;
