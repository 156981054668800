import GeneralStaffDao from "../dao/GeneralStaffDao";

export default class LossService {
    private generalStaffDao: GeneralStaffDao;

    constructor(generalStaffDao: GeneralStaffDao) {
        this.generalStaffDao = generalStaffDao;
    }

    async getRussianLosses(smaDays: number, categories: string[]) {
        return this.generalStaffDao.getLossesOverSma(smaDays, categories);
    }

}
