import AreaDao from "../dao/AreaDao";
import {INCREASE_TABLE_NAME, OCCUPIED_AREA_KEYS} from "../general-staff/generalStaffConstants";
import {DailySnapshot} from "../duckdb/dbTypes";
import GeneralStaffDao from "../dao/GeneralStaffDao";

export default class SnapshotService {
    private areaDao: AreaDao;
    private generalStaffDao: GeneralStaffDao;

    constructor(areaDao: AreaDao, generalStaffDao: GeneralStaffDao) {
        this.areaDao = areaDao;
        this.generalStaffDao = generalStaffDao;
    }

    async getChangeInTerritory(date: Date) {
        const currentlyOccupiedArea = (await this.areaDao.getMostRecentArea(date))
            .filter(a => OCCUPIED_AREA_KEYS.includes(a.type))
            .map(a => a.area)
            .reduce((a, b) => a + b, 0);

        const previouslyOccupiedArea = (await this.areaDao.getPreviousArea(date))
            .filter(a => OCCUPIED_AREA_KEYS.includes(a.type))
            .map(a => a.area)
            .reduce((a, b) => a + b, 0);

        return currentlyOccupiedArea - previouslyOccupiedArea;
    }

    async dailySnapshot(date: string, includeColumns: string[]): Promise<DailySnapshot> {
        const dateParts = date.split('-');
        const [rankedResults, increaseValues, totalValues, fewestMost] = await this.generalStaffDao.getSnapshotData(date, includeColumns);

        return {
            date: new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])),
            dailyTotals: increaseValues,
            sumTotals: totalValues,
            ranks: rankedResults,
            fewerMoreThan: fewestMost,
        };
    }

    async getMostRecentIncreaseDate() {
        return this.generalStaffDao.getMostRecentDate(INCREASE_TABLE_NAME);
    }

}
