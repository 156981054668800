import { AppShell } from '@mantine/core';
import React, { ReactNode } from "react";
import '@mantine/core/styles.css';
import UWSDashboardAppShellNavbar from "./UWSDashboardAppShellNavbar";
import {useRecoilState} from "recoil";
import {navbarCollapsedState} from "../atoms/appShellAtoms";
import Header from "./UWSAppShellHeader";


export function UWSAppShell(props: AppShellProps) {
    const [navbarCollapsed,] = useRecoilState(navbarCollapsedState);

    return (
        <AppShell
            header={{ height: 60 }}
            navbar={props.navbarName === 'dashboard' ? { width: navbarCollapsed ? 45 : 300, breakpoint: 'sm' } : undefined}
            padding="sm"
        >
            {
                props.navbarName && (
                    <UWSDashboardAppShellNavbar />
                )
            }
            <Header />
            <AppShell.Main>
                {props.children}
            </AppShell.Main>
        </AppShell>
    );
}

type AppShellProps = {
    children: ReactNode;
    navbarName?: string;
}
