import React from 'react';
import {
    Text,
    Group,
    HoverCard,
    Anchor,
    Space,
    Stack
} from '@mantine/core';
import '@mantine/dates/styles.css'
import '@mantine/core/styles.css';
import {LinkDescription} from "./dashboardTypes";


function SourceHoverCard(props: SourceHoverCardProps) {

    const sourceElements = Array.from(props.links.entries()).map(([index, linkDescription]) => {
        return <Anchor key={`source-${index}`} href={linkDescription.link} target={"_blank"}>{linkDescription.description}</Anchor>
    });

    return (
        <Group justify="right" style={{marginRight: "10%", bottom: 30, width: "90%"}}>
            <Space h="xl" />
            <HoverCard width={"25%"} shadow="md" arrowSize={50}>
                <HoverCard.Target>
                    <Text c="lightgrey" fw={700} size={"20px"} style={{cursor: 'default', zIndex: 10}}>data sources</Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Stack>
                        {sourceElements}
                    </Stack>
                </HoverCard.Dropdown>
            </HoverCard>
        </Group>
    );
}

type SourceHoverCardProps = {
    links: LinkDescription[];
}

export default SourceHoverCard;
