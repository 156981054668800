import {DailySnapshot, DBSchema, DBTypes} from "../duckdb/dbTypes";
import antiAir from "../asset/antiAir.svg";
import afv from "../asset/apc.svg";
import artillery from "../asset/artillery.svg";
import cruiseMissiles from "../asset/cruise_missiles.svg";
import helicopters from "../asset/helicopters.svg";
import mlrs from "../asset/mlrs.svg";
import personnel from "../asset/personnel.svg";
import planes from "../asset/planes.svg";
import specialEquipment from "../asset/special_equipment.svg";
import submarines from "../asset/submarines.svg";
import trucks from "../asset/trucks.svg";
import warships from "../asset/warships.svg";
import uavs from "../asset/uavs.svg";
import tanks from "../asset/tanks.svg";
import {LinkDescription, SnapshotDisplay} from "./dashboardTypes";

export const GENERAL_STAFF_INCREASE_PARQUET_PATH = 'https://d1dddee6xl2z10.cloudfront.net/general-staff/current/increase/general-staff-increase.parquet';
export const GENERAL_STAFF_TOTAL_PARQUET_PATH = 'https://d1dddee6xl2z10.cloudfront.net/general-staff/current/total/general-staff-total.parquet';
export const DEEP_STATE_AREA_PARQUET_PATH = 'https://d1dddee6xl2z10.cloudfront.net/deep-state/historical/area/area.parquet';

export const CATEGORIES: string[] = [
    'aa_warfare_systems',
    'armoured_fighting_vehicles',
    'artillery_systems',
    'atgm_srbm_systems',
    'cruise_missiles',
    'helicopters',
    'mlrs',
    'personnel_units',
    'planes',
    'special_military_equip',
    'submarines',
    'tanks',
    'uav_systems',
    'vehicles_fuel_tanks',
    'warships_cutters'
];

export const SNAPSHOT_CATEGORIES: string[] = [
    'aa_warfare_systems',
    'armoured_fighting_vehicles',
    'artillery_systems',
    'cruise_missiles',
    'helicopters',
    'mlrs',
    'personnel_units',
    'planes',
    'special_military_equip',
    'submarines',
    'tanks',
    'uav_systems',
    'vehicles_fuel_tanks',
    'warships_cutters'
];

export const INCREASE_TABLE_NAME = 'GeneralStaffIncrease';
export const TOTAL_TABLE_NAME = 'GeneralStaffTotal';
export const AREA_TABLE_NAME = 'DeepStateArea';

export const GENERAL_STAFF_INCREASE_SCHEMA: DBSchema = {
    name: INCREASE_TABLE_NAME,
    fields: [
        {name: 'date', type: DBTypes.string},
        ...CATEGORIES
            .map(f=> {
                return {
                    name: f.valueOf(),
                    type: DBTypes.Integer
                };
            })
    ],
};

export const GENERAL_STAFF_TOTAL_SCHEMA: DBSchema = {
    name: TOTAL_TABLE_NAME,
    fields: [
        {name: 'date', type: DBTypes.string},
        ...CATEGORIES
            .map(f=> {
                return {
                    name: f.valueOf(),
                    type: DBTypes.Integer
                };
            })
    ],
};

export const DEEP_STATE_AREA_SCHEMA: DBSchema = {
    name: AREA_TABLE_NAME,
    fields: [
        {name: 'date', type: DBTypes.string},
        {name: 'hash', type: DBTypes.string},
        {name: 'area', type: DBTypes.Double},
        {name: 'percent', type: DBTypes.string},
        {name: 'type', type: DBTypes.string},
        {name: 'ts_seconds', type: DBTypes.Integer}
    ],
};

const dailyTotals: { [key: string]: number} = {};
SNAPSHOT_CATEGORIES.forEach(cat => {
    dailyTotals[cat] = 0;
})

const ranks: { [key: string]: string } = {};
SNAPSHOT_CATEGORIES.forEach(cat => {
    ranks[cat] = "0";
})

export const DUMMY_DAILY_SNAPSHOT: DailySnapshot = {
    date: new Date(),
    dailyTotals: dailyTotals,
    sumTotals: dailyTotals,
    ranks: ranks,
    fewerMoreThan: {},
}

export const DISPLAY_NAME_MAP: { [key: string]: SnapshotDisplay } = {
    aa_warfare_systems: {name: 'Anti-Air', svg: antiAir},
    armoured_fighting_vehicles: {name: 'AFV/APC', svg: afv},
    artillery_systems: {name: 'Artillery', svg: artillery},
    atgm_srbm_systems: {name: 'ATGM', svg: ""},
    cruise_missiles: {name: 'Cruise Missiles', svg: cruiseMissiles},
    helicopters: {name: 'Helicopters', svg: helicopters},
    mlrs: {name: 'MLRS', svg: mlrs},
    personnel_units: {name: 'Personnel', svg: personnel},
    planes: {name: 'Planes', svg: planes},
    special_military_equip: {name: 'Special Equip', svg: specialEquipment},
    submarines: {name: 'Subs', svg: submarines},
    vehicles_fuel_tanks: {name: 'Trucks/Fuel', svg: trucks},
    warships_cutters: {name: 'Warships', svg: warships},
    uav_systems: {name: 'UAVs', svg: uavs},
    tanks: {name: 'Tanks', svg: tanks},
};

export const DISPLAY_TO_RAW_NAME_MAP: { [key: string]: string } = {
    'Anti-Air': 'aa_warfare_systems',
    'AFV/APC': 'armoured_fighting_vehicles',
    Artillery: 'artillery_systems',
    ATGM: 'atgm_srbm_systems',
    'Cruise Missiles': 'cruise_missiles',
    Helicopters: 'helicopters',
    MLRS: 'mlrs',
    Personnel: 'personnel_units',
    Planes: 'planes',
    'Special Equip': 'special_military_equip',
    Subs: 'submarines',
    'Trucks/Fuel': 'vehicles_fuel_tanks',
    Warships: 'warships_cutters',
    UAVs: 'uav_systems',
    Tanks: 'tanks',
};

export const OCCUPIED_AREA_AFTER_KEY = 'occupied_after_24_02_2022';
export const OCCUPIED_AREA_TO_KEY = 'occupied_to_24_02_2022';
export const OCCUPIED_AREA_KEYS = [OCCUPIED_AREA_AFTER_KEY];
export const ALL_OCCUPIED_AREA_KEYS = [OCCUPIED_AREA_AFTER_KEY, OCCUPIED_AREA_TO_KEY];
export const LIBERATED_AREA_KEY = 'liberated';

export const GENERAL_STAFF_LINK_DESCRIPTION: LinkDescription = {
    link: 'https://russianwarship.rip/',
        description: 'Losses: General Staff of the Armed Forces of Ukraine'
};

export const DEEP_STATE_LINK_DESCRIPTION: LinkDescription =  {
    link: 'https://deepstatemap.live/',
    description: 'Territory: Deep State Map'
};
