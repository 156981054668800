import {Button, Text} from "@mantine/core";
import React from "react";


function HeaderDashboardButton(props: HeaderDashboardButtonProps) {
    return (
        <Button
            variant={"transparent"}
            onClick={props.onClick}
            style={{marginRight: 'auto'}}
            c={'#0057B7'}
        >
            <Text size={'lg'}>Dashboard</Text>
        </Button>
    );
}

type HeaderDashboardButtonProps = {
    onClick: () => void;
};

export default HeaderDashboardButton;
