import React, { useEffect, useState } from 'react';
import {Box, Checkbox, Fieldset} from '@mantine/core';
import SMASlider from "../filter-components/SMASlider";

function FilterOptions(props: FilterOptionProps) {

    const [selectedCategories, setSelectedCategories] = useState(props.selectedCategories);
    const [selectedSMA, setSelectedSMA] = useState(props.selectedSMA);

    useEffect(() => {
        setSelectedCategories(props.selectedCategories)
    }, [props.selectedCategories]);

    useEffect(() => {
        setSelectedSMA(props.selectedSMA)
    }, [props.selectedSMA]);

    function getCheckBoxes(props: FilterOptionProps) {
        return (
            props.categories.map(cat => {
                return (
                    <Checkbox
                        key={`filter-category-${cat}`}
                        checked={selectedCategories.includes(cat)}
                        label={cat}
                        onChange={(c) => props.onCategorySelected(c.currentTarget.checked, cat)}
                    />)
            })
        );
    }

    return (
        <Box>
            <Fieldset
                legend="Filter Loss Categories"
                variant={'filled'}
                radius={10}
                styles={{legend: {fontSize: '20px'}}}
                style={{marginTop: '10%'}}
            >
                {getCheckBoxes(props)}
                <SMASlider minValue={1} maxValue={30} selectedValue={selectedSMA} onChange={props.onSMASelected} />
            </Fieldset>
        </Box>
    );
}

type FilterOptionProps = {
    categories: String[];
    selectedCategories: String[];
    onCategorySelected: (checked: boolean, category: String) => void;
    selectedSMA: number;
    onSMASelected: (value: number) => void;
}

export default FilterOptions;
