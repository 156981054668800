import React, {useEffect, useState} from 'react';
import {Chart, GoogleChartWrapperChartType} from "react-google-charts";
import {Box, Grid, GridCol} from '@mantine/core';
import FilterOptions from "./filter-options/FilterOptions";
import './chart-styles.css'
import {getControls} from "../util/chartUtils";

function FilterableChart(props: FilterableChartProps) {
    const [selectedCategories, setSelectedCategories] = useState(props.selectedCategories);
    const [selectedSma, setSelectedSma] = useState(props.selectedSma);
    const [chartData, setChartData] = useState(props.data);

    useEffect(() => setSelectedCategories(props.selectedCategories), [props.selectedCategories]);

    useEffect(() => setSelectedSma(props.selectedSma), [props.selectedSma]);

    useEffect(() => {
        setChartData(props.data)
    }, [props.data]);

    return (
        <Box pos="relative" style={{height: 670}}>
            <Grid>
                <GridCol span={2.5}>
                    <FilterOptions
                        categories={props.categories}
                        selectedCategories={selectedCategories}
                        onCategorySelected={props.onCategorySelected}
                        onSMASelected={props.onSmaDaysSelected}
                        selectedSMA={selectedSma}
                    />
                </GridCol>
                <GridCol span={9.5}>
                    <Chart
                        chartType={props.chartType}
                        data={chartData}
                        options={props.options}
                        width="100%"
                        height="500px"
                        controls={getControls(props.startDate, props.endDate)}
                    />
                </GridCol>
            </Grid>
        </Box>
    );
}

type FilterableChartProps = {
    data: (String | Number | Date)[][];
    options: Object;
    chartType: GoogleChartWrapperChartType;
    categories: String[];
    selectedCategories: String[];
    onCategorySelected: (checked: boolean, category: String) => void;
    onSmaDaysSelected: (days: number) => void;
    selectedSma: number;
    startDate: Date;
    endDate: Date;
};

export default FilterableChart;
