import React from 'react';
import {Center, Space, Stack, Text} from '@mantine/core';
import './Home.css';

function Home() {
  return (
        <header className="App-header">
            <Stack className="App">
                <Center>
                    <img
                        width="64"
                        alt="Lesser Coat of Arms of Ukraine"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Lesser_Coat_of_Arms_of_Ukraine.svg/64px-Lesser_Coat_of_Arms_of_Ukraine.svg.png"
                    />
                </Center>
                <Space />
                <Text>
                    View the dashboard to analyze daily-updated Russian loss statistics produced by the General Staff of the Ukrainian
                    Armed Forces.
                </Text>
                <Text>
                    This site uses Ukraine General Staff Russian loss figures collected by <a
                    href={'https://russianwarship.rip/en'}>https://russianwarship.rip/</a>.
                </Text>
            </Stack>
        </header>
  );
}

export default Home;
