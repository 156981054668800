import React, {useEffect, useState} from 'react';
import {Button, Text} from "@mantine/core";
import {useLocation} from "react-router-dom";

function NavBarDashboardButton(props: NavBarDashboardButtonProps) {
    const location = useLocation();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (props.dashboardPath === location.pathname) {
            setDisabled(true);
        }
    }, [props.dashboardPath]);

    return (
        <Button
            variant={'transparent'}
            disabled={disabled}
            fullWidth
            leftSection={<Text ta={'left'}>{props.dashboardName}</Text>}
        />
    );
}

type NavBarDashboardButtonProps = {
    dashboardName: string,
    dashboardPath: string,
};

export default NavBarDashboardButton;
