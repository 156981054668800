import React, {useEffect} from 'react';
import '@mantine/core/styles.css';
import {createTheme, MantineProvider} from "@mantine/core";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./home/home";
import GeneralStaffDashboard from "./general-staff/GeneralStaffDashboard";
import {UWSAppShell} from "./layout/UWSAppShell";
import {RecoilRoot} from "recoil";
import About from "./about/About";
import { HelmetProvider } from 'react-helmet-async';
import UWSHelmet from "./layout/UWSHelmet";

function App() {

    useEffect(() => {
        if (['/', ''].includes(window.location.pathname)) {
            window.location.replace('/home');
        }
    }, []);

    return (
        <BrowserRouter>
            <RecoilRoot>
                <HelmetProvider>
                    <MantineProvider theme={createTheme({})}>
                        <Routes>
                            <Route
                                path="/home"
                                element={
                                    <UWSHelmet
                                        title={"Ukraine War Statistics Home Page"}
                                        description={"A website dedicated to live analysis of losses and other facets of the Ukraine War"}
                                        keywords={"Ukraine, war, statistics, dashboard, home page, Ukraine Armed Forces, General Staff, military"}
                                        link={"https://www.ukrainewarstatistics.com/home"}
                                    >
                                        <UWSAppShell><Home /></UWSAppShell>
                                    </UWSHelmet>
                                }
                            />
                            <Route
                                path="/dashboard"
                                element={
                                    <UWSHelmet
                                        title={"Ukraine War Statistics Dashboard"}
                                        description={"Explore Ukraine War statistics"}
                                        keywords={"General Staff loss statistics, losses, chart, dashboard, snapshot, statistics, interactive, live, data analysis, data exploration"}
                                        link={"https://www.ukrainewarstatistics.com/dashboard"}
                                    >
                                        <UWSAppShell><GeneralStaffDashboard /></UWSAppShell>
                                    </UWSHelmet>
                                }
                            />
                            <Route
                                path="/about"
                                element={
                                    <UWSHelmet
                                        title={"About Ukraine War Statistics"}
                                        description={"Site information"}
                                        keywords={"illegal war, links, about, resources"}
                                        link={"https://www.ukrainewarstatistics.com/about"}
                                    >
                                        <UWSAppShell><About /></UWSAppShell>
                                    </UWSHelmet>
                                }
                            />
                        </Routes>
                    </MantineProvider>
                </HelmetProvider>
            </RecoilRoot>
        </BrowserRouter>
    );
}

export default App;
