import AreaDao from "../dao/AreaDao";
import {INCREASE_TABLE_NAME} from "../general-staff/generalStaffConstants";
import {AreaSummary, SummaryRow} from "../duckdb/dbTypes";
import GeneralStaffDao from "../dao/GeneralStaffDao";

export default class SummaryService {
    private areaDao: AreaDao;
    private generalStaffDao: GeneralStaffDao;

    constructor(areaDao: AreaDao, generalStaffDao: GeneralStaffDao) {
        this.areaDao = areaDao;
        this.generalStaffDao = generalStaffDao;
    }

    async summarize(startDate: string, endDate: string): Promise<[SummaryRow[], AreaSummary[]]> {
        const lossSummary = this.generalStaffDao.getSummary(startDate, endDate);
        const areaSummary = this.areaDao.summarizeDailyAreaDeltas(startDate, endDate);
        return await Promise.all([lossSummary, areaSummary]);
    }

    async getMostRecentIncreaseDate() {
        return this.generalStaffDao.getMostRecentDate(INCREASE_TABLE_NAME);
    }

}
