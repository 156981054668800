import React from 'react';
import {Anchor, Divider, Highlight, List, Space, Stack, Text, Title} from '@mantine/core';
import './About.css';

function About() {
    return (
        <header className="App-header">
            <Stack className="App">
                <Title order={1} size={'48px'}>About this Site</Title>
                <Space />
                <Highlight
                    size={'24px'}
                    highlight={['Ukraine War Statistics']}
                    highlightStyles={{
                        backgroundImage: 'linear-gradient(3turn, white, white, #FFDD00, white, white, #0057B7, white)',
                        fontWeight: 700,
                        fontSize: 32,
                        opacity: 0.8,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}>
                    Welcome to Ukraine War Statistics, a source for live visualizations to track the
                    progress of the illegal war in Ukraine.
                </Highlight>
                <Text size={"20px"} c="#DFDFDF">
                    This site uses statistics published by the General Staff of the Ukrainian Armed Forces as
                    collected by <Anchor href={'https://russianwarship.rip/en'} c={"#DFDFDF"}>russianwarship.rip </Anchor>
                    and mapping data collected and published by
                    <Anchor href={'https://deepstatemap.live/'} c={"#DFDFDF"} target={"_blank"}> Deep State Maps</Anchor>
                    . Over time, I plan to add more data sources, visualizations, and other features. If you have
                    suggestions on new visualizations or data sources, please
                    <Anchor href="mailto: ukrainewarstatistics@gmail.com" c={"#DFDFDF"} target={"_blank"}> send an email</Anchor>.
                </Text>
                <Space />
                <Divider size={"lg"} style={{opacity: 0.5}}/>
                <Space />
                <Title order={1}>Additional Resources</Title>
                <Space />
                <Title order={3}>More Statistical Analysis</Title>
                <List spacing={0} listStyleType={"circle"}>
                    <List.Item>
                        <Anchor
                            href="https://www.youtube.com/redirect?event=video_description&redir_token=QUFFLUhqa1lXZTVWU0piaElDZy1MY19TeTBLVkxtTEFhUXxBQ3Jtc0ttZmZKcEhtYl9MYy0yUlpKOUVTOG9STzNKMzFkVFpEUG5lMWJMM2hWOXE0UjRraDhnT1BqTFpLTTJYS0ZrQVRYa09iYXl2aWR6d0s2WFNSYjlBNktFN3FPNDRxNlBCTUdWODhnUkdMbUNuQWdXNVZBOA&q=https%3A%2F%2Fonedrive.live.com%2Fview.aspx%3Fresid%3DE33161B435D127BA%21115352%26ithint%3Dfile%252cxlsx%26authkey%3D%21AIUgFlGIk8-n1hM&v=tqcishp5jtk"
                            target="_blank"
                            c={'#DFDFDF'}
                            size={"24px"}
                        >
                            Dell's visual analysis of Russian losses (UA MOD Total combat losses of the enemy)
                        </Anchor>
                    </List.Item>
                </List>
                <Title order={3}>YouTube Daily News</Title>
                <List spacing={0} listStyleType={"circle"}>
                    <List.Item>
                        <Anchor
                            href="https://www.youtube.com/@UkraineMatters"
                            target="_blank"
                            c={'#DFDFDF'}
                            size={"24px"}
                        >
                            Georgij on Ukraine Matters
                        </Anchor>
                    </List.Item>
                    <List.Item>
                        <Anchor
                            href="https://www.youtube.com/@ATPGeo"
                            target="_blank"
                            c={'#DFDFDF'}
                            size={"24px"}
                        >
                            Jonathan MS Pearce on ATP Geopolitics
                        </Anchor>
                    </List.Item>
                    <List.Item>
                        <Anchor
                            href="https://www.youtube.com/@DenysDavydov"
                            target="_blank"
                            c={'#DFDFDF'}
                            size={"24px"}
                        >
                            Denys Davydov on Update from Ukraine
                        </Anchor>
                    </List.Item>
                    <List.Item>
                        <Anchor
                            href="https://www.youtube.com/@RFU"
                            target="_blank"
                            c={'#DFDFDF'}
                            size={"24px"}
                        >
                            Reporting from Ukraine
                        </Anchor>
                    </List.Item>
                </List>
            </Stack>
        </header>
    );
}

export default About;
