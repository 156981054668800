import DuckDBClient from "../duckdb/duckDBClient";
import {Area} from "../general-staff/dashboardTypes";
import {
    AREA_TABLE_NAME,
    DEEP_STATE_AREA_PARQUET_PATH,
    DEEP_STATE_AREA_SCHEMA,
    OCCUPIED_AREA_KEYS
} from "../general-staff/generalStaffConstants";
import {getDateSqlFromString} from "../util/dbUtils";
import {dateStr} from "../util/dateUtils";
import {round} from "lodash";
import {AreaSummary} from "../duckdb/dbTypes";

export default class AreaDao {
    private dbClient: DuckDBClient;
    private areaIngested = false;

    constructor(db: DuckDBClient) {
        this.dbClient = db;
    }

    async ingestTables(): Promise<void> {
        if (!this.areaIngested) {
            const ingestArea = this.dbClient.ingestTable({schema: DEEP_STATE_AREA_SCHEMA, parquetPath: DEEP_STATE_AREA_PARQUET_PATH});
            await Promise.all([ingestArea]);
            this.areaIngested = true;
        }
        return Promise.resolve();
    }

    async summarizeDailyAreaDeltas(startDate: string, endDate: string): Promise<AreaSummary[]> {
        await this.ingestTables();
        const innerQuery = `SELECT max(date) as date, sum(area) as area, ts_seconds FROM ${AREA_TABLE_NAME} where date BETWEEN ${getDateSqlFromString(startDate)} AND ${getDateSqlFromString(endDate)} AND type in ('${OCCUPIED_AREA_KEYS.join("','")}') group by ts_seconds ORDER BY ts_seconds asc`;
        const query = `SELECT b.date, b.area, row_number() OVER (partition by b.date order by b.ts_seconds desc) as row from (${innerQuery}) b`;
        const byDayQuery = `SELECT a.date, a.area from (${query}) a where a.row = 1`
        const rowDiffQuery = `SELECT f.area - lag(f.area) over (ORDER by f.date) as area from (${byDayQuery}) f`
        const total = (await this.dbClient.query(`SELECT ROUND(SUM(area)) as total_area FROM (${rowDiffQuery})`, AREA_TABLE_NAME))
            .map(r => (r as {total_area: number}).total_area)[0];
        return (await this.dbClient.query(`SUMMARIZE ${rowDiffQuery}`, AREA_TABLE_NAME))
            .map(r => r as { [p: string]: any })
            .map(r => {
                return {
                    total: total,
                    max: round(r.max, 2),
                    min: round(r.min, 2),
                    mean: round(r.avg, 2),
                    median: round(r.q50, 2),
                    '25th percentile': round(r.q25, 2),
                    '75th percentile': round(r.q75, 2),
                    'standard deviation': round(r.std, 3),
                };
            });
    }

    // Return all areas for a given date. Return empty list if no areas found.
    async getArea(date: Date): Promise<Area[]> {
        await this.ingestTables();
        const formattedDate = dateStr(date);
        const query = `SELECT * FROM ${AREA_TABLE_NAME} where date = ${getDateSqlFromString(formattedDate)} ORDER BY ts_seconds desc`;
        const areas = (await this.dbClient.query(query, AREA_TABLE_NAME)).map(r => r as Area)
        console.log('areas', areas);
        return areas.filter(area => area.ts_seconds === areas[0].ts_seconds)
    }

    async getMostRecentArea(date: Date): Promise<Area[]> {
        console.log(`Getting most recent area for date ${date}`);
        await this.ingestTables();
        const formattedDate = dateStr(date);
        console.log('formattedDate', formattedDate);
        const query = `SELECT * FROM ${AREA_TABLE_NAME} where date = ${getDateSqlFromString(formattedDate)} ORDER BY ts_seconds desc`;
        const areas = (await this.dbClient.query(query, AREA_TABLE_NAME)).map(r => r as Area)
        if (areas.length === 0) {
            return await this.getPreviousArea(date);
        }
        return areas.filter(area => area.ts_seconds === areas[0].ts_seconds)
    }

    // Return all areas for the most recent non-empty date prior to beforeDate
    async getPreviousArea(beforeDate: Date): Promise<Area[]> {
        await this.ingestTables();
        const dateFilter = getDateSqlFromString(dateStr(beforeDate));
        const query = `SELECT * FROM ${AREA_TABLE_NAME} where date = (SELECT max(date) FROM ${AREA_TABLE_NAME} where date < ${dateFilter}) ORDER BY ts_seconds desc`
        const areas = (await this.dbClient.query(query, AREA_TABLE_NAME)).map(r => r as Area);
        return areas.filter(area => area.ts_seconds === areas[0].ts_seconds)
    }

}
