import React, {useEffect, useState} from 'react';
import {Box, Center, Title, TitleOrder} from "@mantine/core";

function WidgetHeader(props: WidgetHeaderProps) {

    const [widgetTitle, setWidgetTitle] = useState(props.title);

    useEffect(() => {
        setWidgetTitle(props.title);
    }, [props.title]);

    return (
        <>
            <Box>
                <Title
                    c={props.color ? props.color : '#0057B7'}
                    order={props.order ? props.order as TitleOrder : 1}
                    style={
                        {
                            marginBottom: 0,
                            WebkitTextStroke: props.textStroke ? props.textStroke : 0,
                            WebkitTextStrokeColor: props.textStrokeColor ? props.textStrokeColor : 'black',
                        }
                    }
                    td={props.td ? props.td : undefined}
                    ta="left"
                >
                    {widgetTitle}
                </Title>
            </Box>
            <div style={{paddingBottom: 20}}/>
        </>
)
    ;
}

type WidgetHeaderProps = {
    title: string;
    order?: number;
    color?: string;
    td?: string;
    textStroke?: number;
    textStrokeColor?: string;
};

export default WidgetHeader;
