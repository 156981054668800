import React, {ReactNode} from "react";
import {Helmet} from "react-helmet-async";


function UWSHelmet(props: UWSHelmetProps) {
    return (
        <div>
            <Helmet prioritizeSeoTags>
                <title>{props.title}</title>
                <meta name="descripton" content={props.description} />
                <meta name="keywords" content={props.keywords} />
                <meta name="author" content="Ukraine War Statistics" />
                <link rel="canonical" href={props.link} />
            </Helmet>
            {props.children}
        </div>

    );
}

type UWSHelmetProps = {
    children: ReactNode;
    title: string;
    description: string;
    keywords: string;
    link: string;
};

export default UWSHelmet;
