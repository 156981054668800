import React, {useState} from 'react';
import {Box, LoadingOverlay, Paper} from '@mantine/core';
import _, {max, min} from "lodash";
import FilterableChart from "../charts/FilterableChart";
import {
    CATEGORIES,
    DISPLAY_NAME_MAP,
    DISPLAY_TO_RAW_NAME_MAP,
    GENERAL_STAFF_LINK_DESCRIPTION
} from "./generalStaffConstants";
import '@mantine/core/styles.css';
import WidgetHeader from "../dashboards/WidgetHeader";
import SourceHoverCard from "./SourceHoverCard";
import LossService from "../service/LossService";

const defaultData: (String | Number | Date)[][] = [['date', 'Select a loss category'], [new Date(), 1], [new Date(), 1]];

const convertToDisplayNames = (categories: (string | String)[]) => categories.map(c => DISPLAY_NAME_MAP[c.valueOf()].name);

function GeneralStaffChart(props: GeneralStaffChartProps) {
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<String[]>([]);
    const [filteredData, setFilteredData] = useState(defaultData);
    const [smaDays, setSmaDays] = useState(30);

    const getFilteredData = async (newSelectedCategories?: String[], newSmaDays?: Number): Promise<(String | Number | Date)[][]> => {
        if ((newSelectedCategories && newSelectedCategories.length > 0) || (newSmaDays && selectedCategories.length > 0)) {
            setDataLoading(true);
            const losses = await props.lossService.getRussianLosses((newSmaDays || smaDays || 1) as number, (newSelectedCategories || selectedCategories) as string[]);
            setDataLoading(false);
            return losses;
        }
        return Promise.resolve(defaultData);
    }

    const options = {
        legend: {position: 'bottom'},
        chartArea: {left: 50, right: 30, top: 50, bottom: 30},
        vAxis: {
            viewWindow: {
                min: 0,
                max: selectedCategories.length === 0 ? 10 :
                    _.max([10, ...filteredData.slice(1).flatMap(r => r.slice(1) as Number[])])
            }
        },
        curveType: 'function',
    };

    const onSmaDaysSelected = (days: number) => {
        setSmaDays(days);
        getFilteredData(undefined, days)
            .then(data => {
                setFilteredData(data);
            })
            .catch(err => console.log(err))
    }

    const onCategorySelected = (checked: boolean, category: String) => {
        const selectedCategory = DISPLAY_TO_RAW_NAME_MAP[category.valueOf()];

        const categorySet = new Set(selectedCategories);
        if (checked) {
            categorySet.add(selectedCategory);
        } else {
            categorySet.delete(selectedCategory);
        }
        const newSelectedCategories = Array.from(categorySet).sort();
        setSelectedCategories(newSelectedCategories);
        getFilteredData(newSelectedCategories, undefined).then(data => {
            setFilteredData(data);
        })
    }

    return (
        <Box pos="relative">
            <Paper shadow="md" p="xl" style={{height: '100%'}}>
                <WidgetHeader title={"Daily Losses over Time"} />
                <LoadingOverlay
                    visible={dataLoading}
                    zIndex={1000}
                    overlayProps={{ radius: "sm", blur: 2 }}
                />
                <div>
                    <FilterableChart
                        chartType="LineChart"
                        data={filteredData}
                        options={options}
                        categories={convertToDisplayNames(CATEGORIES)}
                        selectedCategories={convertToDisplayNames(selectedCategories)}
                        onCategorySelected={onCategorySelected}
                        selectedSma={smaDays}
                        onSmaDaysSelected={onSmaDaysSelected}
                        startDate={min(filteredData.slice(1).map(row => row[0] as Date)) as Date}
                        endDate={max(filteredData.slice(1).map(row => row[0] as Date)) as Date}
                    />
                </div>
                <SourceHoverCard links={[GENERAL_STAFF_LINK_DESCRIPTION]} />
            </Paper>
        </Box>
    );
}

type GeneralStaffChartProps = {
    lossService: LossService
}

export default GeneralStaffChart;
