export const WAR_START_DATE = new Date(2022, 1, 24);
export const MAP_START_DATE = new Date(2022, 3, 3);

export const INCREASE_START_DATE = new Date(2022, 3, 17);

export const IMPORTANT_EVENTS: { [key: string]: { actor: ('Ukraine' | 'Russia'), event: string } } = {
    '2022-04-13': {
        actor: 'Ukraine',
        event: 'Russian cruiser Moskva sinks'
    },
    '2022-05-20': {
        actor: 'Russia',
        event: 'Russia occupies Mariupol'
    },
    '2022-08-01': {
        actor: 'Russia',
        event: 'Battle for Bakhmut begins'
    },
    '2022-09-06': {
        actor: 'Ukraine',
        event: "Kharkiv offensive begins"
    },
    '2022-10-02': {
        actor: 'Ukraine',
        event: "Kharkiv offensive ends"
    },
    '2022-11-09': {
        actor: 'Ukraine',
        event: 'Kherson liberated'
    },
    '2023-05-20': {
        actor: 'Russia',
        event: 'Russia captures Bakhmut'
    },
    '2023-06-08': {
        actor: 'Ukraine',
        event: "2023 counteroffensive begins"
    },
    '2023-10-10': {
        actor: 'Ukraine',
        event: "Ukraine's 2023 counteroffensive ends"
    },
    '2023-10-20': {
        actor: 'Russia',
        event: '2023 Fall offensive begins'
    },
    '2024-02-17': {
        actor: 'Russia',
        event: 'Russia occupies Avdiivka'
    }
}