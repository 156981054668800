import {GoogleChartControlProp} from "react-google-charts";
import {AreaDelta, TotalArea} from "../general-staff/dashboardTypes";
import {max, min, round} from "lodash";
import {dateStr, fillDateArray, stringToDate} from "./dateUtils";
import {IMPORTANT_EVENTS} from "./constants";

const UKRAINE_FLAG = '\uD83C\uDDFA\uD83C\uDDE6';
const RUSSIA_FLAG = '\uD83C\uDDF7\uD83C\uDDFA';

export const getControls = (startDate: Date | undefined, endDate: Date | undefined): GoogleChartControlProp[] | undefined => {
    return [
        {
            controlType: "ChartRangeFilter" as "ChartRangeFilter",
            options: {
                filterColumnIndex: 0,
                ui: {
                    chartType: "LineChart",
                    chartOptions: {
                        chartArea: { width: "90%", height: "50%" },
                        hAxis: { baselineColor: "none" },
                    },
                },
            },
            controlPosition: "bottom" as "bottom",
            controlWrapperParams: {
                state: {
                    range: {
                        start: startDate,
                        end: endDate,
                    },
                },
            },
        },
    ];
}

export const formatAreaDeltasAndRows = (areaDeltas: AreaDelta[]): [AreaDelta[], {v: (Date | number | string)}[][]] => {
    const newAreaDeltas = [areaDeltas[0]];

    // add 0s when lines cross the axis to help with Google color formatting
    for (let i = 0; i < areaDeltas.length - 1; i++) {
        newAreaDeltas.push(areaDeltas[i]);
        if (areaDeltas[i][1] * areaDeltas[i + 1][1] < 0) {
            const ratio = Math.abs(areaDeltas[i][1]) / (Math.abs(areaDeltas[i][1]) + Math.abs(areaDeltas[i + 1][1]));
            const timeDifference = areaDeltas[i + 1][0].getTime() - areaDeltas[i][0].getTime();
            const newDate = new Date(areaDeltas[i][0].getTime() + timeDifference * ratio);
            newAreaDeltas.push([newDate, 0]);
            // need to add a 0 value here.
        }
    }

    const rows = Array.from(newAreaDeltas.entries())
        .sort((a, b) => a[1][0] > b[1][0] ? 1 : a[1][0] < b[1][0] ? -1 : 0).map(a => {
        const index = a[0];
        const area = a[1];
        const currentValue = newAreaDeltas[index][1];
        const previousValue = index > 0 ? newAreaDeltas[index - 1][1] : 0;
        const color = currentValue < 0 || (currentValue === 0 && previousValue <= 0)  ? '#0057B7' : '#880808';
        const tooltipString = `${round(Math.abs(area[1]), 2)} sq km ${area[1] < 0 ? 'liberated' : 'occupied'} on ${area[0].toDateString()}`
        return [{v: area[0]}, {v: area[1]}, {v: color}, {v: tooltipString}];
    });
    return [newAreaDeltas, rows]
};

export const formatTotalAreasAndRows = (totalAreas: TotalArea[]): {v: (Date | number | string | null)}[][] => {
    const minDate = min(totalAreas.map(a => stringToDate(a.date)))!!;
    const maxDate = max(totalAreas.map(a => stringToDate(a.date)))!!;
    const dateArray = fillDateArray(minDate, maxDate);
    const dateToTotalAreas: { [key: string]: [TotalArea | null, string | null, string | null]} =
        Object.fromEntries(dateArray.map(d => [dateStr(d), [null, null, null]]));
    console.log("totalAreas", totalAreas);
    console.log()
    totalAreas.forEach(a => {
        try {
            dateToTotalAreas[a.date][0] = a;
        } catch (e) {
            console.log(e);
            console.log("a.date", a.date);
        }
    });

    // add important events
    Object.entries(IMPORTANT_EVENTS).forEach(e => {
        const date = e[0];
        if (stringToDate(date) <= maxDate && stringToDate(date) >= minDate) {
            dateToTotalAreas[date][1] = e[1].actor === 'Ukraine' ? UKRAINE_FLAG : RUSSIA_FLAG;
            dateToTotalAreas[date][2] = e[1].event;
        }
    });

    const totalAreaEntries = Array.from(Object.entries(dateToTotalAreas));
    // fill in missing total areas.
    for (let i = 1; i < totalAreaEntries.length; i++) {
        const currentDate = totalAreaEntries[i][0]
        if (dateToTotalAreas[currentDate][0] === null) {
            const area = {...totalAreaEntries[i - 1][1][0]} as TotalArea;
            area.date = currentDate;
            area.ts_seconds = Number(BigInt(3600) * BigInt(24) + BigInt(area.ts_seconds));
            dateToTotalAreas[currentDate][0] = area;
        }
    }

    const sortedEntries = Object.entries(dateToTotalAreas)
        .sort((a, b) => a > b ? 1 : a === b ? 0 : -1);
    return sortedEntries.map(a => [
        {v: stringToDate(a[0])},
        {v: a[1][0] ? a[1][0].area : null},
        {v: 'color: #880808; opacity: 0.4; fill-color: #880808;'},
        {v: a[1][0] ? `On ${stringToDate(a[0]).toDateString()}, ${a[1][0].percent} occupied (${round(a[1][0].area)} sq km)`: null},
        {v: a[1][1]},
        {v: a[1][2]},
    ]);
};

