import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Paper,
    Text,
    LoadingOverlay,
    Grid,
    GridCol,
    BackgroundImage,
    Center,
    Group,
    Tooltip,
    Space
} from '@mantine/core';
import {DatePickerInput} from '@mantine/dates';
import '@mantine/dates/styles.css'
import {DailySnapshot} from "../duckdb/dbTypes";
import '@mantine/core/styles.css';
import WidgetHeader from "../dashboards/WidgetHeader";
import {
    DEEP_STATE_LINK_DESCRIPTION,
    DISPLAY_NAME_MAP,
    DUMMY_DAILY_SNAPSHOT,
    GENERAL_STAFF_LINK_DESCRIPTION,
    SNAPSHOT_CATEGORIES
} from "./generalStaffConstants";
import {useMeasure} from "react-use";
import {INCREASE_START_DATE} from "../util/constants";
import _ from "lodash";
import {dateStr, stringToDate} from "../util/dateUtils";
import SourceHoverCard from "./SourceHoverCard";
import SnapshotService from "../service/SnapshotService";


function GeneralStaffSnapshot(props: GeneralStaffSnapshotTableProps) {
    const [dataLoading, setDataLoading] = useState(true);
    const [dailyData, setDailyData] = useState<DailySnapshot>(DUMMY_DAILY_SNAPSHOT);
    const [areaDiff, setAreaDiff] = useState(0);
    const [mostRecentDate, setMostRecentDate] = useState(INCREASE_START_DATE);
    const [date, setDate] = useState(INCREASE_START_DATE);

    const imageRef = useRef<HTMLInputElement>(null);
    const [setRef, { width }] = useMeasure();

    useEffect(() => {
        if (imageRef?.current) {
            setRef(imageRef.current)
        }
    }, [setRef]);

    const getCellTooltipLabel = (index: number, dailyTotal: number) => {
        const [moreThan, fewerThan] = Object.entries(dailyData.fewerMoreThan).slice(index * 2, index * 2 + 2)
        if (!moreThan || !fewerThan) {
            return '';
        }
        const equipmentName = DISPLAY_NAME_MAP[Object.keys(dailyData.dailyTotals)[index]].name
        if (dailyTotal <= 0) {
            const days = Math.round((date.getTime() - Date.parse(moreThan[1])) / 1000 / 24 / 60 / 60);
            const plural = days > 1 ? 's' : '';
            const message = isNaN(days) ?
                `No ${equipmentName} destroyed since before Apr 17 2022.` :
                `${days} day${plural} since ${equipmentName} were last destroyed.`
            return <Text>{message}</Text>
        }

        const percentile = parseInt(Object.values(dailyData.ranks)[index])
        const lastDigit = percentile % 10;
        const prefix = [11, 12, 13].includes(percentile) ? 'th' : lastDigit === 1 ? 'st' : lastDigit === 2 ? 'nd' : lastDigit === 3 ? 'rd' : 'th';
        const rankString = `${percentile}${prefix} percentile`
        let message: string;
        if (moreThan[1] === null) {
            message = 'Most since before Apr 17 2022';
        } else if (fewerThan[1] === null) {
            message = 'Fewest since before Apr 17 2022';
        } else if (moreThan[1] < fewerThan[1]) {
            message = `Most since ${stringToDate(moreThan[1]).toDateString()}`;
        } else {
            message = `Fewest since ${stringToDate(fewerThan[1]).toDateString()}`;
        }
        return <><Text fw={700} size={"lg"} ta={"center"}>{rankString}</Text><Text ta={"center"}>{message}</Text></>;
    }

    const getCells = () => {
        const dailyTotals = Object.entries(dailyData.dailyTotals);
        const dailySums = Object.entries(dailyData.sumTotals);

        return _.range(0, dailyTotals.length).map(index => {
            return (
                <GridCol span={1} key={`General-Staff-Snapshot-Cell-${index}`}>
                    <Center><Text fw={900} c={"lightgrey"}>-{dailySums[index][1]}</Text></Center>
                    <Tooltip
                        label={getCellTooltipLabel(index, dailyTotals[index][1])}
                        position={"bottom"}
                        multiline
                        transitionProps={{ transition: "slide-down", duration: 300 }}
                        color={"grey"}
                        w={"14%"}
                        radius={10}
                    >
                        <Paper shadow={"xlg"} radius={10} withBorder>
                            <BackgroundImage
                                src={DISPLAY_NAME_MAP[dailyTotals[index][0]].svg}
                                ref={imageRef}
                                style={{height: width}}
                            >
                                <Center>
                                    <Text fw={700} c={"white"}>{DISPLAY_NAME_MAP[dailyTotals[index][0]].name}</Text>
                                </Center>
                            </BackgroundImage>
                            <Center>
                                <Text fw={700} size={"lg"}>+{dailyTotals[index][1].toString()}</Text>
                            </Center>
                        </Paper>
                    </Tooltip>
                </GridCol>
            );
        })
    }

    const getAreaText = () => {
        const textColor = areaDiff < 0 ? '#0057B7' : areaDiff > 0 ? '#880808' : 'lightgrey';
        const areaText =
            areaDiff > 0 ?
                `Ukrainian land temporarily lost to Russian aggression: ${areaDiff.toFixed(2)} sq km` :
            areaDiff < 0 ?
                `Ukrainian land liberated from Russian aggression: ${(areaDiff * -1).toFixed(2)} sq km` :
                'No change in occupied territory published';
        return (<Text c={textColor} fw={700} size={"24px"}>{areaText}</Text>)
    }

    useEffect(() => {
        setDataLoading(true);
        props.snapshotService.getMostRecentIncreaseDate()
            .then(async mostRecentDate => {
                setDate(mostRecentDate);
                setMostRecentDate(mostRecentDate);
                const dailySnapshot = props.snapshotService.dailySnapshot(dateStr(mostRecentDate), SNAPSHOT_CATEGORIES);
                const areaDiff = props.snapshotService.getChangeInTerritory(mostRecentDate);
                return Promise.all([dailySnapshot, areaDiff])
                    .then(values => {
                        setDailyData(values[0]);
                        setAreaDiff(values[1]);
                        setDataLoading(false);
                    })
            })
            .catch((err) => {
                console.error(err);
                setDataLoading(false);
            })
    }, [props.snapshotService]);

    const onChangeDate = async (date: Date) => {
        setDataLoading(true);
        setDate(date);
        Promise.all([props.snapshotService.dailySnapshot(dateStr(date), SNAPSHOT_CATEGORIES),
            props.snapshotService.getChangeInTerritory(date)])
            .then(values => {
                setDailyData(values[0]);
                setAreaDiff(values[1])
                setDataLoading(false);
            })
    };

    return (
        <Box pos="relative">
            <Paper shadow="xl" p="xl" style={{height: '100%'}}>
                <Group gap={6}>
                    <WidgetHeader title={`Russian losses as of`} order={1} />
                    <Center>
                        <DatePickerInput
                            variant={"transparent"}
                            style={{maxWidth: 360}}
                            styles={{input: {fontSize: 32, fontWeight: 700, color: 'grey', verticalAlign: "top", paddingLeft: 0}}}
                            value={date}
                            onChange={(value) => {
                                if (value !== null) {
                                    onChangeDate(value);
                                }
                            }}
                            excludeDate={(value) => {
                                return value.getTime() < INCREASE_START_DATE.getTime() || value.getTime() > mostRecentDate.getTime();
                            }}
                        />
                    </Center>
                </Group>
                <LoadingOverlay
                    visible={dataLoading}
                    zIndex={1000}
                    overlayProps={{ radius: "sm", blur: 2 }}
                />
                <Grid gutter={"lg"} style={{marginBottom: 50, marginTop: 30, marginLeft: 50, marginRight: 50}} columns={7}>
                    {getCells()}
                </Grid>
                <Space />
                {getAreaText()}
                <SourceHoverCard links={[GENERAL_STAFF_LINK_DESCRIPTION, DEEP_STATE_LINK_DESCRIPTION]} />
            </Paper>
        </Box>
    );
}

type GeneralStaffSnapshotTableProps = {
    snapshotService: SnapshotService;
    collapsed: boolean;
}

export default GeneralStaffSnapshot;
